import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatButton } from '@angular/material/button';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatIcon } from '@angular/material/icon';
import { MatFormField, MatSuffix, MatLabel } from '@angular/material/form-field';
import { MatCard, MatCardHeader, MatCardAvatar, MatCardTitle, MatCardContent, MatCardActions, MatCardFooter } from '@angular/material/card';

@Component({
    selector: 'app-bookme',
    templateUrl: './bookme.component.html',
    styleUrls: ['./bookme.component.css'],
    standalone: true,
    imports: [
        MatCard,
        MatCardHeader,
        MatCardAvatar,
        MatCardTitle,
        MatCardContent,
        MatFormField,
        MatIcon,
        MatSuffix,
        MatLabel,
        MatInput,
        ReactiveFormsModule,
        FormsModule,
        MatCardActions,
        MatButton,
        MatCardFooter,
        MatProgressBar,
        TranslateModule,
    ],
})
export class BookmeComponent implements OnInit {
  constructor(
    private utils: UtilitiesService,
    private toast: ToastrService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.translate.get('app.landing.A.bookingModal').subscribe((res) => {
      this.translatedText = res;
    });
  }
  translatedText: any;
  email: string;
  phone: number;
  ngOnInit(): void {}
  value = 0;
  async send() {
    if (!this.email && !this.phone) {
      this.toast.error(
        this.translatedText.toast.error.missingInputText,
        this.translatedText.toast.error.missingInputTitle
      );
    } else {
      if (this.phone && !(await this.utils.checkNumber(this.phone))) {
        this.toast.error(
          this.translatedText.toast.error.invalidPhoneText,
          this.translatedText.toast.error.invalidPhoneTitle
        );
      } else {
        while (this.value < 100) {
          this.value += 25;
          await this.waitOneSecond();
        }
        this.toast.success(this.translatedText.toast.success);
        this.dialog.closeAll();
        await this.utils.sendContactDetails({
          landing: 'A',
          contact: this.email ?? this.phone.toString(),
        });
      }
    }
  }
  waitOneSecond() {
    return new Promise((resolve) => setTimeout(resolve, 250));
  }
}
