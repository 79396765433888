<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>
      {{ "app.landing.A.bookingModal.title" | translate }}
    </mat-card-title>
    <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
  </mat-card-header>

  <mat-card-content>
    <div style="display: flex; flex-direction: column; margin: 5%">
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-icon matSuffix>alternate_email</mat-icon>
        <mat-label>{{
          "app.landing.contactModal.email" | translate
        }}</mat-label>
        <input type="text" matInput [(ngModel)]="email" />
      </mat-form-field>
      <h2 class="razor">{{ "app.landing.A.bookingModal.or" | translate }}</h2>
      <mat-form-field class="example-full-width" appearance="outline">
        <mat-label>
          {{ "app.landing.A.bookingModal.phone" | translate }}
        </mat-label>
        <input
          type="tel"
          matInput
          placeholder="78-89-02-00"
          [(ngModel)]="phone"
        />
        <mat-icon matSuffix>call</mat-icon>
      </mat-form-field>
    </div>
  </mat-card-content>
  <mat-card-actions style="display: flex; justify-content: end">
    <button
      mat-button
      (click)="send()"
      style="
        background-color: var(--phonix-blue-white);
        color: white;
        border-radius: 25px;
      "
    >
      {{ "app.landing.A.bookingModal.send" | translate }}
    </button>
  </mat-card-actions>
  <mat-card-footer>
    <mat-progress-bar mode="determinate" [value]="value"></mat-progress-bar>
  </mat-card-footer>
</mat-card>
